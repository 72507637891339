import * as React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Formik, Form, Field, ErrorMessage } from "formik"

const Contact = ({ data, location }) => {
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  const siteTitle = data.site.siteMetadata?.title || `Title`
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Contact" />
      <div className="p-6 md:p-1 h-auto flex flex-col items-center pt-3 md:justify-evenly">
        <div className="md:self-center lg:w-1/2 font-nunito p-3">
          <h2 className="text-3xl text-center md:text-6xl dark:text-slate-800">
            Contact Me
          </h2>
          <h3 className="pt-3 text-lg md:text-xl text-center text-stone-700 dark:text-slate-900">
            Send me a message if you have any questions, want to work together,
            or just want to say hi.
          </h3>
        </div>
        <Formik
          initialValues={{
            name: "",
            email: "",
            message: "",
          }}
          onSubmit={(values, actions) => {
            fetch("/", {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: encode({ "form-name": "Contact Form", ...values }),
            })
              .then(() => {
                actions.resetForm()
                navigate("/thank-you")
              })
              .catch(() => {
                console.warn("unable to submit contact form")
              })
              .finally(() => actions.setSubmitting(false))
          }}
          validate={values => {
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
            const errors = {}
            if (!values.name) {
              errors.name = "Name Required"
            }
            if (!values.email || !emailRegex.test(values.email)) {
              errors.email = "Valid Email Required"
            }
            if (!values.message) {
              errors.message = "Message Required"
            }
            return errors
          }}
        >
          {({ errors, touched, dirty, isSubmitting, isValid }) => (
            <Form
              name="Contact Form"
              method="POST"
              data-netlify={true}
              netlify-honeypot="bot-field"
              className="flex flex-col w-full md:w-1/2"
            >
              <input type="hidden" name="form-name" value="Contact Form" />
              <input type="hidden" name="bot-field" />
              <div className="w-full mb-2">
                <label htmlFor="name" className="sr-only">
                  please enter your name
                </label>
                <Field
                  as="input"
                  id="name"
                  className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 dark:hover:border-cyan-700 border-2  bg-white dark:bg-zinc-400 font-nunito appearance-none relative block w-full px-3 py-2 border-gray-300 dark:border-slate-800 placeholder-gray-500 dark:placeholder-gray-700 text-gray-900 rounded-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:focus:ring-cyan-800 dark:focus:border-cyan-800 focus:z-10 sm:text-lg text-2xl"
                  type="text"
                  name="name"
                  placeholder="Name"
                ></Field>
                <ErrorMessage name="name">
                  {msg => (
                    <div className="font-nunito dark:text-sky-900 text-sky-800 text-md">
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </div>
              <div className="w-full mb-2">
                <label htmlFor="email" className="sr-only">
                  please enter your email address
                </label>
                <Field
                  as="input"
                  id="email"
                  className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 dark:hover:border-cyan-700 border-2 bg-white dark:bg-zinc-400 font-nunito appearance-none relative block w-full px-3 py-2 border-gray-300 dark:border-slate-800 placeholder-gray-500 dark:placeholder-gray-700 text-gray-900 rounded-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:focus:ring-cyan-800 dark:focus:border-cyan-800 focus:z-10 sm:text-lg text-2xl"
                  type="email"
                  name="email"
                  placeholder="name@name.com"
                ></Field>
                <ErrorMessage name="email">
                  {msg => (
                    <div className="font-nunito dark:text-sky-900 text-sky-800 text-md">
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </div>
              <div className="w-full mb-2">
                <label htmlFor="message" className="sr-only">
                  please enter your message
                </label>
                <Field
                  as="textarea"
                  id="message"
                  className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 dark:hover:border-cyan-700 border-2 bg-white dark:bg-zinc-400 font-nunito appearance-none relative block w-full px-3 py-2 border-gray-300 dark:border-slate-800 placeholder-gray-500 dark:placeholder-gray-700 text-gray-900 rounded-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:focus:ring-cyan-800 dark:focus:border-cyan-800 focus:z-10 sm:text-lg text-2xl"
                  name="message"
                  placeholder="type in your message"
                ></Field>
                <ErrorMessage name="message">
                  {msg => (
                    <div className="font-nunito dark:text-sky-900 text-sky-800 text-md">
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </div>
              <div data-netlify-captcha></div>
              <button
                className={`font-nunito appearance-none relative block w-full px-3 py-2 placeholder-gray-500 dark:placeholder-gray-200   text-xl rounded-none focus:outline-none ${
                  !isValid
                    ? "text-gray-700 dark:text-zinc-400 bg-gray-400 dark:bg-slate-700"
                    : "transition ease-in-out delay-150 hover:scale-105 text-gray-900 dark:text-zinc-200 dark:bg-slate-800"
                }`}
                type="submit"
                value="Submit"
              >
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
